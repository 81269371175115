import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const PostImage = (props) => {
  const domNode = props.domNode;
  if (props.coverImage) {
    const options = JSON.parse(domNode.children[0].data);
    const Wrapper = ({children}) => {
      return <>{ children }</>;
    };
    const sizeSlug = props.oneLastNews ? "xl":"medium";
    return (<Wrapper {...options}>
      <GatsbyImage
        className="wp-block-post-featured-image"
        image={ props.coverImage.localFile.childImageSharp[sizeSlug] || props.coverImage.localFile.childImageSharp.gatsbyImageData }
        alt={
          props.coverImage.altText
          || props.coverImage.caption
        }
      />
    </Wrapper>);
  }
  return (<></>);
};

export default PostImage;
